import { PreloaderOverlay } from "@rototip/lib-ui/common/PreloaderOverlay";
import Head from "next/head";
import { lazy, Suspense } from "react";

const CustomErrorDisplay = lazy(
	() => import("@rototip/lib-ui/error/CustomErrorDisplay")
);

export default function Custom404() {
	return (
		<>
			<Head>
				<title>Page Not Found | Rototip Web App</title>
			</Head>
			<Suspense fallback={<PreloaderOverlay fit="screen" />}>
				<CustomErrorDisplay code={404} message="Page not found" />
			</Suspense>
		</>
	);
}
